<template lang="pug">
    .companies.view
        .content-wrapper
            .header
                .h2 {{ title }}
                el-button(@click="$router.push({ name: 'subscription.edit' })" icon="el-icon-plus" type="success" size="small" plain) Добавить подписку
            .totals-cards
                div
                    b {{ companies.total || 0 }}
                    div
                        div всего
                div
                    b ?
                    div
                        div за квартал
                div
                    b ?
                    div
                        div за месяц
                div
                    b ?
                    div
                        div сегодня
            .content.full-width.transparent
                .title
                    .h1 Недавние
                    div
                        el-button(@click="filter_visible = !filter_visible" :icon="filter_visible ? 'el-icon-close' : 'el-icon-s-operation'" type="primary" size="small" plain)

                .table
                    .th
                        .td Название
                        .td Дата
                    .tr(v-for="row in companies.data" @click="$router.push({ name: 'subscription.details', params: { id: row.id } })" class="clickable" :key="row.id")
                        .td
                            b {{ row.name }}
                        .td {{ $moment(row.updated_at).local().format('lll') }}
                .pagination(v-if="false" )
                    el-button(:plain="false" type="primary" size="small" ) 1
                    el-button(type="primary" size="small" plain) 2
                    el-button(type="primary" size="small" plain) 3
</template>

<script>
    import { mapActions } from 'vuex'
    import { GET_COMPANIES } from '@/store/types'

    export default {
        metaInfo() {
            return {
                title: this.title
            }
        },
        data() {
            return {
                filter_visible: false,
                companies: {},
            }
        },
        computed: {
            title(){
                return 'Компании'
            },
        },
        created() {
            this.getCompanies()
        },
        methods: {
            ...mapActions([
                GET_COMPANIES
            ]),
            getCompanies(){
                return this.GET_COMPANIES({ page: 1 }).then((companies)=>{
                    this.companies = companies
                }).catch((error)=>{
                    this.$root.pushAppMessages(error.response.data)
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .companies{
        .header{
            display: flex;
            align-items: center;

            .h3{
                margin-left: $padding-global;
            }
        }
        .totals-cards{
            padding-top: $padding-global;
        }
        .title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: $padding-global;
        }
        .filter{
            padding-top: $padding-global;

        }
        .table{
            padding-top: $padding-global;
        }
    }
</style>
